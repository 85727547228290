/* Navbar.css */

/* Base Side Navigation Styles with Tailwind-inspired gradient background */
.sideNav {
    display: flex;
    flex-direction: column;
    height: 100%;
    background-image: linear-gradient(to bottom, #040808, #020303);
    /* Tailwind: slate-800 to gray-700 */
    transition: width 0.3s ease;
    overflow: hidden;
}

/* Expanded vs Collapsed */
.sideNav.expanded {
    width: 220px;
    /* Make sure to add px */
}

.sideNav.collapsed {
    width: 60px;
    /* Show just icons */
}

/* Toggle Button: positioned on top of the nav */
.toggleButton {
    background: none;
    border: none;
    font-size: 1.5rem;
    color: #ffffff;
    margin: 1rem;
    cursor: pointer;
    outline: none;
}

/* Brand container */
.brandContainer {
    text-align: center;
    margin-bottom: 1rem;
}

.brandExpanded {
    font-weight: bold;
    font-size: 1.4rem;
    color: #ffffff;
}

.brandCollapsed {
    font-weight: bold;
    font-size: 1.2rem;
    color: #ffffff;
}

/* Nav links container */
.navLinks {
    display: flex;
    flex-direction: column;
}

/* Each nav link */
.navItem {
    display: flex;
    align-items: center;
    gap: 0.75rem;
    text-decoration: none;
    padding: 1rem;
    transition: background-color 0.2s ease, color 0.2s ease;
}

/* Icon style */
.navIcon {
    font-size: 1.2rem;
}

/* Responsive behavior for small screens */
@media (max-width: 768px) {
    .sideNav.expanded {
        position: absolute;
        top: 0;
        left: 0;
        z-index: 999;
        /* Ensure it covers content */
    }

    .sideNav.collapsed {
        position: relative;
    }
}


/*
<aside className="w-56 bg-gradient-to-b from-[#0E1D1B] to-[#080D10] p-4 shadow-lg rounded-r-xl">
<button className="text-2xl mb-4 md:hidden" onClick={() => setNavExpanded(!navExpanded)}>
  {navExpanded ? <FaTimes /> : <FaBars />}
</button>

<div className={`space-y-4 ${navExpanded ? 'block' : 'hidden'} md:block`}>
  <h2 className="text-xl font-bold text-center">Desync</h2>

  {[
    { to: '/home', label: 'Home' },
    { to: '/core', label: 'Desync Core' },
    { to: '/tools', label: 'Desync Tools' },
    { to: 'https://beta.desync.ai/home', label: 'Log In/Sign Up', external: true },
  ].map(({ to, label, external }) => (
    <NavLink
      key={to}
      to={to}
      target={external ? "_blank" : undefined}
      className={({ isActive }) => `block p-2 rounded-lg transition-colors duration-200 ${isActive ? 'bg-[#2B2F2F]' : 'hover:bg-[#00BFA6] hover:text-black'}`}
    >
      {label}
    </NavLink>
  ))}
</div>
</aside>

*/


